import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { AccountAvailableCash } from '../type';
import { Accounts } from '../../enum';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { useAccountAvailableCashStringifiedParams } from './defaultParams';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { makeApiUrl } from '../../../../../../aws';

export const useMakeAccountsAvailableCashUrl = () => {
  const stringifiedParams = useAccountAvailableCashStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.AvailableCash)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountAvailableCashApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountAvailableCash[]>,
  isValidating: boolean,
}

type UseAccountAvailableCashLinesApiResponse = {
  data: undefined | AccountAvailableCash[],
} & BaseUseAccountAvailableCashApiResponse;

type FilterPredicate = (accountsAvailableCash: AccountAvailableCash) => boolean;

type SortPredicate = (prevAccountAvailableCash: AccountAvailableCash, nextAccountAvailableCash: AccountAvailableCash) => number;

type UseAccountAvailableCashLinesApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountAvailableCashLinesApi = (props: UseAccountAvailableCashLinesApiProps): UseAccountAvailableCashLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsAvailableCashUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountAvailableCash[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((availableCash) => filterPredicate(availableCash));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountsAvailableCashLines: AccountAvailableCash[] | undefined) => T

type UseAccountAvailableCashLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountAvailableCashLinesApiProps

type UseAccountAvailableCashApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountAvailableCashApiResponse;

export function useAccountAvailableCashLinesTransformApi<T>(props: UseAccountAvailableCashLinesApiTransformProps<T>): UseAccountAvailableCashApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountAvailableCashLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountAvailableCashApiResponse = {
  data: AccountAvailableCash | undefined,
} & BaseUseAccountAvailableCashApiResponse;

export const useAccountAvailableCashApi = (props: UseAccountAvailableCashLinesApiProps): UseAccountAvailableCashApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountsAvailableCashUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountAvailableCash[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountAvailableCash[] | AccountAvailableCash | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((availableCash) => filterPredicate(availableCash));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountsAvailableCash: AccountAvailableCash | undefined) => T

type UseAccountAvailableCashApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountAvailableCashLinesApiProps;

export function useAccountAvailableCashTransformApi<T>(props: UseAccountAvailableCashApiTransformProps<T>): UseAccountAvailableCashApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountAvailableCashApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountAvailableCashFieldApiResponse<Field extends keyof AccountAvailableCash> = {
  data: AccountAvailableCash[Field] | undefined,
} & BaseUseAccountAvailableCashApiResponse;

type UseAccountAvailableCashFieldApiProps<Field extends keyof AccountAvailableCash> = {
  field: Field,
} & UseAccountAvailableCashLinesApiProps

export function useAccountAvailableCashFieldApi<Field extends keyof AccountAvailableCash>(props: UseAccountAvailableCashFieldApiProps<Field>): UseAccountAvailableCashFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountAvailableCashApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
