import { useContext, useMemo } from 'react';
import { AccountAvailableCashParams } from '../type';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsAvailableCashParamsContext } from '../context';
import { useIsConnected } from '../../../../../../Components/Auth';

export const useAccountAvailableCashDefaultParams = (params: Partial<AccountAvailableCashParams>): Partial<AccountAvailableCashParams> => {
  const {
    'T_ACC.ID': contextTAccId,
  } = useContext(accountsAvailableCashParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  return defaultParams;
};

export const useAccountAvailableCashParams = (): AccountAvailableCashParams | null => {
  const defaultParams = useAccountAvailableCashDefaultParams({});
  const tAccId = defaultParams['T_ACC.ID'];
  const isConnected = useIsConnected();
  if (!tAccId || !isConnected) {
    return null;
  }
  return {
    'T_ACC.ID': tAccId,
  };
};

export const useAccountAvailableCashStringifiedParams = () => {
  const contextParams = useAccountAvailableCashParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
