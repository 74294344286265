import * as React from 'react';
import { ActorApiNumberDisplayCurrency } from '../../../../../../components/display/number/currency';
import { useAccountAvailableCashCCurrencyQt, useAccountAvailableCashCCurrencyCode } from '../../hooks';

export const AccountAvailableCashCCurrencyQt = (props: {
  format?: Parameters<typeof ActorApiNumberDisplayCurrency>[0]['format'],
  defaultValue?: number,
  defaultCurrency?: string,
}) => {
  const {
    format,
    defaultValue,
    defaultCurrency,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountAvailableCashCCurrencyQt();
  const {
    data: currencyData,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useAccountAvailableCashCCurrencyCode();
  return (
    <ActorApiNumberDisplayCurrency
      data={data ?? defaultValue}
      isLoading={isLoading}
      error={error}
      currencyData={currencyData ?? defaultCurrency}
      currencyIsLoading={currencyIsLoading}
      currencyError={currencyError}
      format={format}
    />
  );
};
