import useSWR from 'swr';
import { GetAccountsTradingMarginParams, GetAccountsTradingMarginResponse } from '@goldwasserexchange/actor/rest-services/actor-rest.generated';
import { useIsConnected } from '../../../../../../Components/Auth';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { Accounts } from '../../enum';
import { makeApiUrl } from '../../../../../../aws';
import { getServiceUrl } from '../../../../shared';
import { useMemo } from 'react';

export const useMakeAccountTradingMarginUrl = (params: Partial<GetAccountsTradingMarginParams>) => {
  const isConnected = useIsConnected();
  if (params['T_ACC.ID'] == null || !isConnected) {
    return null;
  }
  const stringifiedParams = stringifyParams(params);
  const url = `${makeApiUrl('actor')}/${getServiceUrl(Accounts.TradingMargin)}${stringifiedParams}`;
  return url;
};

export const useAccountTradingMargin = (params: GetAccountsTradingMarginParams, run = true) => {
  const url = useMakeAccountTradingMarginUrl(params);
  const fetcher = useFetchWithAuthWithSend();
  const response = useSWR<GetAccountsTradingMarginResponse[], any>(
    run === true ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const result = useMemo(() => {
    const { data, error, ...rest } = response;
    const isLoading = data == null && error == null;
    return {
      ...rest,
      data: data != null ? data[0] : undefined,
      error,
      isLoading,
    };
  }, [response.data, response.isLoading]);
  return result;
};
